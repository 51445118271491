import React from "react";
import logo_mark from "../img/main/logo_mark.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Section_Four = ({ config }) => {
  const navigate = useNavigate();

  const goToTermsOfUse = () => {
    navigate("/termsofuse");
  };

  return (
    <StyledLayout>
      <div id="section3" className="section fp-auto-height">
        <div id="footer">
          <div className="inner">
            <div
              className="tail_layout"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <address className="address_layout">
                <div style={{ display: "flex" }}>
                  <div className="logo">
                    <img src={logo_mark} alt={""} />
                  </div>
                  <div>
                    <h1> (주)엠에이디씨 </h1>
                    <p>
                      <span>
                        <strong>사업자 번호 : </strong> 606-88-02385
                      </span>
                      <span>
                        <strong>대표이사 : </strong> 김홍민
                      </span>
                      <span>
                        <strong>대표자 전화번호: </strong> 02-6925-5866
                      </span>
                    </p>
                    <p className="co">
                      COPYRIGHT(c) 2022 <strong>MADC</strong> ALL RIGHTS
                      RESERVED
                    </p>
                  </div>
                </div>
              </address>
              <StyledTextLayout>
                <StyledText onClick={goToTermsOfUse}>
                  개인정보취급방침
                </StyledText>
                <StyledText onClick={() => navigate("/information")}>
                  서비스이용약관
                </StyledText>
              </StyledTextLayout>
            </div>
          </div>
        </div>
      </div>
    </StyledLayout>
  );
};

export default Section_Four;

const StyledTextLayout = styled.div`
  display: flex;
  gap: 0.5em;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 2em;

  @media (max-width: 470px) {
    display: flex;
    justify-content: center;
    align-item: center;
    margin-top: 2em;
    margin-right: 0em;
  }
`;

const StyledText = styled.span`
  cursor: pointer;

  @media (max-width: 470px) {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-item: center;
  }
`;

const StyledLayout = styled.div`
  .address_layout {
    display: flex;
    flex-direction: row;
    justifycontent: space-between;
    align-items: center;
    width: 100%;
    // height: 100%;
    padding: 0px 5% 0px 10px;
  }

  @media (max-width: 470px) {
    .inner {
      padding: 0px;
    }
    .footer {
      display: flex;
      flex-direction: column;
    }

    .address_layout {
      display: flex;
      flex-direction: column;
    }

    .tail_layout {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    .inner {
      padding: 0px;
    }
  }

  @media (max-width: 1200px) {
    .inner {
      padding: 0px;
      margin: 0px;
      width: 95%;
    }
  }
`;
