import "./App.css";
import Index from "./page/Index";
import "./css/animate.min.css";
// import "./css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/common.css";
import "./css/font.css";
import "./css/main.css";
import "./css/swiper.min.css";
import "./css/_notes/dwsync.xml";
import "./css/default.css";
import "./css/_notes/default.css";
import { Route, Routes } from "react-router-dom";
import TermsofUse from "./page/TermsofUse";
import Information from "./page/Information";
import "@shinyongjun/react-fullpage/css";

function App() {
  return (
    <div
      className="App"
      style={{
        fontSize: 16,
      }}
    >
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/termsofuse" element={<TermsofUse />} />
        <Route path="/information" element={<Information />} />
      </Routes>
    </div>
  );
}

export default App;
