import React, { useState, useEffect } from "react";
import btn_top from "../img/common/btn_top.gif";

const ScrollFixedButton = () => {
  const [buttonStyle, setButtonStyle] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      // 사용자가 보고 있는 뷰포트의 80% 위치에 버튼이 위치하도록 설정
      setButtonStyle({
        position: "absolute",
        top: `${window.scrollY + window.innerHeight * 0.8}px`,
        left: `${window.scrollX + window.innerWidth * 0.8}px`,
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button style={buttonStyle}>
      <img src={btn_top} alt="btn_top" />
    </button>
  );
};

export default ScrollFixedButton;
