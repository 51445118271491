import React from "react";
import MapKakao from "./MapKakao";
import styled from "styled-components";

const Section_Third = () => {
  return (
    <>
      <StyledMain>
        <div className="text">
          <p className="font_gw">오시는 길</p>
          <span>
            서울특별시 금천구 가산디지털2로 169-23, 1101-1호(가산모비우스타워)
          </span>
        </div>
      </StyledMain>
      <MapKakao />
    </>
  );
};

export default Section_Third;

const StyledMain = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1%;

  @media (max-width: 470px) {
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`;
