import React from "react";
import site01 from "../img/main/site01.png";
import site02 from "../img/main/site02.png";
import styled from "styled-components";
import { Carousel } from "antd";

const Section_Second = () => {
  const contentStyle = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <>
      <StyledMain>
        <Carousel arrows autoplay dots={false}>
          <div className="inner">
            <div
              className="slide"
              data-anchor="slide1"
              style={{
                padding: "0.5% 0 0 0",
              }}
            >
              <div className="img1">
                <img src={site01} alt="Site 01" />
              </div>
              <div
                className="text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="font_gw">나비톡</p>
                <span>우리끼리만 아는 나만의 비밀톡</span>
                <a
                  href="http://www.navitalk.co.kr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="circle_btn"
                >
                  WEBSITE
                </a>
              </div>
            </div>
          </div>
          <div className="inner">
            <div
              className="slide"
              data-anchor="slide2"
              style={{
                padding: "5% 0 0 0",
              }}
            >
              <div className="img2">
                <img src={site02} alt="Site 02" />
              </div>
              <div
                className="text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="font_gw">엠애드컴퍼니</p>
                <span>소통과 실행능력이 우선인 광고대행사</span>
                <a
                  href="http://madcompany.co.kr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="circle_btn"
                >
                  WEBSITE
                </a>
              </div>
            </div>
          </div>
        </Carousel>
      </StyledMain>
    </>
  );
};

export default Section_Second;

const StyledMain = styled.div`
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;

  .react-fullpage__contents {
    min-height: 0%;
  }

  .img1 {
    display: flex;
    justify-content: center;
  }

  .img2 {
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-arrow.slick-next::after {
    display: block;
    // width: 50px; /* 적절한 크기 조정 */
    // height: 50px; /* 적절한 크기 조정 */
    color: red; /* 화살표 색상 */
    position: absolute;
    left: -1em; /* 위치 조정 */
    border-style: solid;
    border-width: 35px 0 35px 35px; /* 화살표 두께 조정 */
    border-color: transparent transparent transparent red; /* 화살표 색상 조정 */
    transform: translate(-50%, -50%); /* 중앙 정렬 */
  }

  .slick-arrow.slick-prev::after {
    display: block;
    // width: 50px; /* 적절한 크기 조정 */
    // height: 50px; /* 적절한 크기 조정 */
    color: red; /* 화살표 색상 */
    position: absolute;
    left: 2em; /* 위치 조정 */
    // border-style: solid;
    border-width: 35px 35px 35px 0; /* 화살표 두께 조정 */
    border-color: transparent red transparent transparent;
    transform: translate(-50%, -50%); /* 중앙 정렬 */
  }

  @media (max-width: 768px) {
    // height: 100vh;
    .fp-arrow {
      display: none;
    }
    .slick-arrow.slick-next::after {
      display: none;
    }

    .slick-arrow.slick-prev::after {
      display: none;
    }

    .img1 img {
      width: 80%;
    }

    .img2 img {
      width: 70%;
    }
  }

  @media (max-width: 870px) {
    .img2 img {
      width: 80%;
    }
  }

  // @media (max-width: 660px) {
  //   // height: 100vh;
  //   .fp-arrow {
  //     display: none;
  //   }

  //   .inner img {
  //     width: 100%;
  //   }
  // }

  @media (max-width: 470px) {
    padding: 30% 0;

    .slick-arrow.slick-next::after {
      display: none;
    }

    .slick-arrow.slick-prev::after {
      display: none;
    }

    .inner img {
      width: 90%;
    }
  }
`;
