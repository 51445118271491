const tintColorLight = '#2f95dc'

export default {
  text: '#000',
  background: '#fff',
  tint: tintColorLight,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColorLight,
  primary: '#23B9D6',
  primaryLighten: '#EDF8FA',
  secondary: '#FF82AF',
  white: '#fff',
  black: '#333',
  gray1: '#fafafa',
  gray2: '#f3f5f9',
  gray3: '#ededed',
  gray4: '#aaaaaa',
  gray5: '#555555',
  error: '#ED0000',
}
