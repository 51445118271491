import React, { useEffect } from "react";
import styled from "styled-components";
// import styled from "styled-components";

const MapKakao = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=2e5e9ac9779d87294fc49639ed9d7d55&autoload=false`;
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(() => {
        // 여기서 맵 초기화 및 사용 코드를 실행
        const container = document.getElementById("map");
        const options = {
          center: new window.kakao.maps.LatLng(37.4822377, 126.8780596),
          level: 4,
        };

        const map = new window.kakao.maps.Map(container, options);

        const markerPosition = new window.kakao.maps.LatLng(
          37.4822377,
          126.8767596
        );
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });

        // 마커 지도에 표시
        marker.setMap(map);

        // 인포윈도우 생성
        const infowindow = new window.kakao.maps.InfoWindow({
          content: '<div style="padding:5px;">카카오 지도 위치</div>',
        });

        // 마커 클릭 이벤트
        window.kakao.maps.event.addListener(marker, "click", () => {
          infowindow.open(map, marker);
        });
      });
    };
  }, []);

  return (
    <StyledText>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="map_layout"
      >
        <div id="map" className="map" />
      </div>
    </StyledText>
  );
};

export default MapKakao;

const StyledText = styled.div`
  width: 100%;
  .map {
    width: 50%;
    height: 400px;
    border: 10px solid lightgray;
    borderradius: 5px;
  }

  @media (max-width: 470px) {
    width: 100%;

    .map {
      width: 80%;
      height: 400px;
      border: 10px solid lightgray;
      borderradius: 5px;
    }
  }
`;
