import React from "react";
import { Col, Layout, Row } from "antd";
import styled from "styled-components";
import logo from "../img/icon_logo_mark.png";
import home from "../img/icon_home_primary.png";
import BasicText from "./BasicText";
import Colors from "./Colors";
import Link from "antd/es/typography/Link";

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 48px;
  width: 100%;
  background-color: ${Colors.primaryLighten};
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

// privacy header
const PrivacyHeader = () => {
  return (
    <StyledRow justify="space-between" align="middle">
      <StyledHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          <img src={logo} width={30} alt="logo" />
          {/* <BasicText bold={700} size={30} color={Colors.gray4}>
            나비톡
          </BasicText> */}
        </div>
        <div>
          <Link href="/">
            <img src={home} width={30} alt="home" />
          </Link>
        </div>
      </StyledHeader>
    </StyledRow>
  );
};

export default PrivacyHeader;
