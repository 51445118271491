import React from "react";
import styled from "styled-components";
import Colors from "./Colors";

const BasicText = ({
  children,
  bold = 400,
  size = 14,
  lineHeight = "14.4px",
  color = Colors.black,
  ...restProps
}) => {
  return (
    <StyledText
      bold={bold}
      size={size}
      lineHeight={lineHeight}
      color={color}
      {...restProps}
    >
      {children}
    </StyledText>
  );
};

export default BasicText;

const StyledText = styled.span`
  font-family: ${({ bold }) => getFontFamily(bold)};
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size}px;
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
`;

const getFontFamily = (bold) => {
  const fontWeight = bold || 400; // bold 값이 undefined일 경우 기본값 400을 사용

  switch (fontWeight) {
    case 400:
      return "Pretendard-Regular";
    case 500:
      return "Pretendard-Medium";
    case 600:
      return "Pretendard-SemiBold";
    case 700:
      return "Pretendard-Bold";
    default:
      return "Pretendard-Regular";
  }
};
