// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Titillium+Web&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GangwonEduPowerExtraBoldA";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.font_gw {
  font-family: "GangwonEduPowerExtraBoldA";
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAGhB;EACE,iCAAiC;EACjC;kBACgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC;kBACgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":["@charset \"UTF-8\";\n@import url(\"https://fonts.googleapis.com/css?family=Titillium+Web&display=swap\");\n\n@font-face {\n  font-family: \"Pretendard-Regular\";\n  src: url(\"https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff\")\n    format(\"woff\");\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"GangwonEduPowerExtraBoldA\";\n  src: url(\"https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff\")\n    format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n.font_gw {\n  font-family: \"GangwonEduPowerExtraBoldA\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
