import React from "react";
import main from "../img/main/logo_mark.png";
import s1_img from "../img/main/s1_img.png";
import styled from "styled-components";
import main_bg from "../img/main/main_bg.jpg";

const SectionFirst = () => {
  return (
    <StyeldMain>
      <div className="text_area">
        <div className="layout">
          <div className="logo">
            <img
              src={main}
              className="wow fadeInUpBig animated"
              data-wow-delay="0s"
              data-wow-duration="2s"
              alt=""
            />
          </div>
          <div className="text_layout">
            <span
              className="wow fadeInUp animated"
              data-wow-delay="0.5s"
              data-wow-duration="1s"
            >
              소비자와 브랜드, 그 사이에서 서로가 상생하는 혁신
              <br />
              <strong>세상에 없던 새로운 가치를 창출</strong>하고
              <br />
              다음 단계를 바라보는 기업
              <br />
            </span>

            <p
              className="font_gw wow fadeInUp animated"
              data-wow-delay="1s"
              data-wow-duration="1s"
            >
              엠에이디씨는 <strong>혁신</strong>을 바라봅니다.
            </p>
          </div>
        </div>

        <div
          className="img wow fadeInRight animated"
          data-wow-delay="1s"
          data-wow-duration="1.5s"
          style={
            {
              // padding: "0 0 0 50px",
            }
          }
        >
          <img src={s1_img} alt="computer" className="right_img" />
        </div>
      </div>
    </StyeldMain>
  );
};

export default SectionFirst;

const StyeldMain = styled.div`
  background-color: #fff;
  background-image: url(${main_bg});
  background-size: 100% 250%;
  backgroundrepeat: no-repeat;

  .text_area {
    display: flex;
    justify-content: center;
    align-items: center;
    alignitems: center;
    height: 100vh;
    gap: 9%;
  }

  @media (max-width: 1201px) {
    display: flex;
    justify-content: center;

    .right_img {
      width: 100%;
    }

    .logo {
      margin-left: 1em;
    }
    .text_layout {
      margin-left: 1em;
    }
  }

  @media (max-width: 787px) {
    .text_area {
      display: flex;
      flex-direction: column-reverse; // 세로 방향 역순 배치
      // background-color: red;
    }
  }

  @media (max-width: 470px) {
    padding: 10px;
    .text_area {
      display: flex;
      flex-direction: column-reverse; // 세로 방향 역순 배치
      // background-color: red;
    }

    .img wow fadeInRight animated img {
      width: 20%;
    }

    .logo img {
      width: 150px; // 로고 이미지 너비
      animation: fadeInUp 1s ease-out; // 애니메이션 효과
    }

    .right_img {
      display: flex;
      justify-content: center;
      alignitems: center;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .layout {
      display: flex;
      flex-direction: column;
    }
  }
`;
