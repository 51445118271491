// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Titillium+Web&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GangwonEduPowerExtraBoldA';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.font_gw{font-family: 'GangwonEduPowerExtraBoldA';}`, "",{"version":3,"sources":["webpack://./src/css/font.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAGhB;IACI,iCAAiC;IACjC,gHAAgH;IAChH,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;IACxC,wHAAwH;IACxH,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA,SAAS,wCAAwC,CAAC","sourcesContent":["@charset \"UTF-8\";\r\n@import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap');\r\n\r\n@font-face {\r\n    font-family: 'Pretendard-Regular';\r\n    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');\r\n    font-weight: 400;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'GangwonEduPowerExtraBoldA';\r\n    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff') format('woff');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n\r\n.font_gw{font-family: 'GangwonEduPowerExtraBoldA';}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
